import { createStore } from 'vuex'
export default createStore({
  state: {
    keywords: '牧云时代',
  },
  mutations: {
    updateKeyWords(state, newvalue) {
      state.keywords = newvalue
    },
  },
  actions: {},
  modules: {},
})
