<!--  手机端-->
<template>
  <div ref="mapp" class="mapp" @scroll="scrollbarScroll">
    <div ref="headMenu" class="headMenu van-safe-area-top">
      <div
        :class="
          !isPopupShow
            ? 'headMenu-left-content-logo'
            : 'headMenu-left-content-logo labellogo'
        "
      >
        <img
          @click="jump('')"
          class="logoImgleft"
          src="/img/logo.png"
          alt="加载失败"
        />
        <img
          v-if="!isPopupShow"
          @click="isPopupShow = true"
          class="headMenu_mapp_right"
          src="/img/headMenu_mapp_right.png"
          alt="加载失败"
        />
        <img
          v-if="isPopupShow"
          @click="isPopupShow = false"
          class="headMenu_mapp_right"
          src="/img/headMenu_mapp_right-2.png"
          alt="加载失败"
        />
      </div>
    </div>
    <!-- 菜单弹出 -->
    <div
      class="van-overlay"
      v-show="isPopupShow"
      @click.prevent="isPopupShow = false"
    >
      <div ref="headMenuContent" class="headMenuContent">
        <div class="mapp-publicpadding" @click.stop>
          <div v-for="(i, index) in serviceList" :key="i">
            <div
              @click="
                serviceListClick(index),
                  i.route ? jump(i.route) : (i.active = !i.active)
              "
              :class="
                !i.active ? 'headMenuContent-1 label' : 'headMenuContent-1'
              "
            >
              <div
                @click.stop="
                  serviceListClick(index),
                    i.route ? jump(i.route) : (i.active = !i.active)
                "
              >
                {{ i.name }}
              </div>
              <van-icon
                @click.stop="serviceListClick(index), (i.active = !i.active)"
                v-if="!i.active && index !== serviceList.length - 1"
                name="arrow-down"
              />
              <van-icon
                @click.stop="serviceListClick(index), (i.active = !i.active)"
                v-if="i.active && index !== serviceList.length - 1"
                name="arrow-up"
              />
            </div>
            <div v-if="i.active">
              <div v-for="v in i.list" :key="v">
                <div class="headMenuContent-2">{{ v.name }}</div>
                <div class="content-1">
                  <div
                    @click.stop="
                      v2.route ? jump(v2.route) : false, (isPopupShow = false)
                    "
                    class="content-1-1"
                    v-for="v2 in v.contentList"
                    :key="v2"
                  >
                    <img
                      style="width: 24px; height: 24px; margin-right: 8px"
                      :src="v2.src"
                      alt=""
                    />
                    {{ v2.text }}
                    <span
                      class="djz"
                      :style="{ color: v2.active ? '#DCE3EC' : '' }"
                      v-if="v2.active"
                    >
                      待建中
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播图 -->
    <van-swipe
      v-if="!type"
      class="my-swipe"
      indicator-color="white"
      autoplay="5000"
    >
      <van-swipe-item v-for="item in bannerList" :key="item">
        <div class="banner">
          <van-image
            class="img"
            fit="cover"
            width="100%"
            height="100%"
            :src="item.img"
          />
          <div class="banner1" v-if="!item.type">
            <div :style="item.style || ''" class="name" v-if="item.name">
              {{ item.name }}
            </div>
            <div
              :style="item.textstyle || ''"
              class="text"
              style="margin-bottom: 0; opacity: 0.8"
              v-if="item.text"
            >
              {{ item.text }}
            </div>
            <div v-if="item.tagList.length > 0" class="tag">
              <div class="tag-div" v-for="i in item.tagList" :key="i">
                <span>
                  {{ i }}
                </span>
              </div>
            </div>
            <!-- <div :class="item.style ? 'bac mar' : 'mar'">
              <el-button v-if="item.button" type="primary">{{
                item.button
              }}</el-button>
            </div> -->
          </div>
          <div class="banner2" v-else>
            <div class="name" v-if="item.name">
              {{ item.name }}
            </div>
            <div class="text" v-if="item.text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <!-- 首页图公共 -->
    <div
      class="bannerSlice"
      v-if="
        type === 'CDN' ||
        type === 'byjsy' ||
        type === 'byrqy' ||
        type === 'byyjs' ||
        type === 'gywm' ||
        type === 'KVM' ||
        type === 'SD-WAN' ||
        type === 'byjspt' ||
        type === 'yyxjjfa'
      "
    >
      <div class="banner">
        <img
          class="img"
          style="width: 100%; height: 100%"
          :src="publicImgObj.img"
          alt=""
        />
        <div class="banner1" v-if="!publicImgObj.type">
          <div
            :style="type === 'yyxjjfa' ? 'color:#FFFFFF' : ''"
            class="name"
            v-if="publicImgObj.name"
          >
            {{ publicImgObj.name }}
          </div>
          <div
            :style="type === 'yyxjjfa' ? 'color:#FFFFFF' : ''"
            class="text"
            v-if="publicImgObj.text"
          >
            {{ publicImgObj.text }}
          </div>
          <!-- <div :class="type === 'yyxjjfa' ? 'clo' : ''">
            <el-button v-if="publicImgObj.button" type="primary">{{
              publicImgObj.button
            }}</el-button>
          </div> -->
        </div>
        <div class="banner2" v-else>
          <div class="name" v-if="publicImgObj.name">
            {{ publicImgObj.name }}
          </div>
          <div class="text" v-if="publicImgObj.text">
            {{ publicImgObj.text }}
          </div>
        </div>
      </div>
    </div>
    <!-- 产品类型 -->
    <div v-if="type === 'KVM'" class="cplx CDN2 mapp-publicpadding mapp-bac">
      <div class="mapp-publitext">产品类型</div>
      <div class="mapp-publicontent">
        <div class="CDN2-c">
          <div class="CDN2-left">
            <div class="bot">
              <span
                @click="kvmContentClick(index)"
                :class="i.active ? 'botimg isActiveHover' : 'botimg'"
                v-for="(i, index) in kvmContentList"
                :key="i"
              >
                <span class="text">
                  {{ i.name }}
                </span>
              </span>
            </div>
          </div>
          <div class="label-content">
            <div
              v-for="i in kvmContentList[activeKvmName].list"
              class="content"
              :key="i"
            >
              <img
                v-if="i.isHot"
                class="img"
                style="width: 47px; height: 47px"
                src="/img/KVM_slices/hot.png"
                alt=""
              />
              <div class="left">
                <div class="left-1">{{ i.name }}</div>
                <div class="left-2">{{ i.text }}</div>
                <div class="left-c">
                  <div class="left-c-c" v-for="v in i.gnList" :key="v">
                    <div class="c-1">{{ v.title }}</div>
                    <div class="c-2">{{ v.text }}</div>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="right-1">
                  {{ i.cjObj.name }}
                </div>
                <div class="right-c">
                  <div class="right-c-c" v-for="v2 in i.cjObj.cjList" :key="v2">
                    <span class="right-c-c-c">
                      {{ v2.text }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品特性 -->
    <div
      v-if="
        type === 'CDN' ||
        type === 'byjsy' ||
        type === 'byrqy' ||
        type === 'byyjs' ||
        type === 'KVM' ||
        type === 'SD-WAN' ||
        type === 'byjspt' ||
        type === 'yyxjjfa'
      "
      class="CDN1 mapp-publicpadding"
    >
      <div class="slicehover2" v-if="type === 'byyjs'">
        <div
          v-for="i in slicesHoverByjsyList"
          :key="i"
          class="slicehover12"
          :style="{
            background: `url(${i.img}) no-repeat`,
            backgroundSize: '100%',
            height: '122px',
            width: '100%',
          }"
        >
          <div class="right">
            <div class="name">{{ i.name }}</div>
            <div class="text">{{ i.text }}</div>
          </div>
        </div>
      </div>
      <div class="mapp-publitext">
        {{ type === 'yyxjjfa' ? '方案优势' : '产品特性' }}
      </div>
      <div class="CDN1 mapp-publicontent">
        <div class="CDN1-img" v-for="i in cdnlist.slice(0, 4)" :key="i">
          <div class="CDN1-img-1">
            <img
              class="img"
              :src="i.img"
              alt=""
              style="width: 36px; height: 36px; margin-right: 5px"
            />
            <span class="text1">{{ i.name }} </span>
          </div>
          <div class="text2">{{ i.text }}</div>
        </div>
        <div
          class="canme"
          @click="cdnlisttype = true"
          v-if="!cdnlisttype && cdnlist.length > 4"
        >
          查看更多
          <van-icon name="arrow-down" />
        </div>
        <div v-if="cdnlisttype">
          <div class="CDN1-img" v-for="i in cdnlist.slice(4, 6)" :key="i">
            <div class="CDN1-img-1">
              <img
                class="img"
                :src="i.img"
                alt=""
                style="width: 36px; height: 36px; margin-right: 5px"
              />
              <span class="text1">{{ i.name }} </span>
            </div>
            <div class="text2">{{ i.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用场景 -->
    <div
      v-if="
        type === 'CDN' ||
        type === 'byjsy' ||
        type === 'byrqy' ||
        type === 'KVM' ||
        type === 'SD-WAN' ||
        type === 'byjspt' ||
        type === 'yyxjjfa'
      "
      class="CDN2 mapp-publicpadding mapp-bac"
    >
      <div class="mapp-publitext">
        {{ type === 'yyxjjfa' ? '方案架构' : '应用场景' }}
      </div>
      <div class="mapp-publicontent">
        <div class="CDN2-c">
          <div class="CDN2-left">
            <div class="bot">
              <span
                @click="cdnClick(index)"
                :class="i.active ? 'botimg isActiveHover' : 'botimg'"
                v-for="(i, index) in cdnBotList"
                :key="i"
              >
                <span class="bimg">
                  <img style="width: 24px" :src="i.img" alt="" />
                </span>
                <span class="text">
                  {{ i.name }}
                </span>
              </span>
            </div>
          </div>
          <div class="CDN2-right">
            <img
              style="width: 100%; height: 100%"
              :src="cdnObj.obj.bac"
              alt=""
            />
          </div>
          <div class="text tex">
            {{ cdnObj.obj.text }}
          </div>
        </div>
      </div>
    </div>
    <!-- 节点分布 -->
    <div v-if="type === 'CDN'" class="CDN3 mapp-publicpadding">
      <div class="mapp-publitext">节点分布</div>
      <div class="mapp-publicontent">
        <el-radio-group v-model="typePosition">
          <el-radio-button label="0">全球</el-radio-button>
          <el-radio-button label="1">国内</el-radio-button>
        </el-radio-group>
        <div class="CDN3-left">
          {{
            typePosition == 0
              ? ' 牧云智能云海外CDN，依托分布于全球50+个国家和地区的200+个CDN海外节点，超过100T带宽资源储备，有效解决跨境访问问题，降低海外终端用户访问延迟，提升服务可用性。'
              : '目前牧云智能云CDN支持国内节点1000+个，线路接入电信、联通、移动、广电、铁通、鹏博士、华数、BGP等网络，覆盖全国30多个省市，为用户提供可靠的服务。'
          }}
        </div>
        <div class="CDN3-bot-right">
          <img
            style="width: 100%; height: 100%"
            :src="cdnDtList[typePosition].img"
            alt=""
          />
        </div>
        <div class="CDN3-bot">
          <div :class="typePosition == 0 ? 'CDN3-bot-c' : 'CDN3-bot-c gn'">
            <div
              v-for="v in cdnDtList[typePosition].list"
              :key="v"
              class="CDN3-bot-left"
              :style="typePosition == 0 ? 'padding-bottom: 0px;' : ''"
            >
              <div class="img">
                <img
                  style="width: 9px; height: 13px"
                  src="/img/cdn_sjx.png"
                  alt=""
                />
              </div>
              <div>
                <div class="CDN3-bot-left-name">
                  {{ v.name }}
                </div>
                <div class="CDN3-bot-left-num">
                  <span v-if="v.size" :style="v.size">{{ v.num }}</span>
                  <span v-else> {{ v.num }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 首页 type为空 -->
    <div v-if="!type || type === 'gywm'" style="background: #09193a">
      <!-- 首页内容1 -->
      <img
        v-if="!type"
        src="/img/m_sy_slices/dzy.png"
        style="width: 100%"
        alt=""
      />
      <div v-if="!type" class="home-content mapp-publicpadding">
        <div class="mapp-publitext" style="color: #fff">
          性能强大、安全、稳定的云产品
        </div>
        <div class="home-content-subject mapp-publicontent">
          <div
            @mousemove="i.fadeshow = true"
            @mouseleave="i.fadeshow = false"
            @click="jump(i.route)"
            class="home-content-img"
            v-for="i in homeContentList"
            :key="i"
          >
            <div class="text">
              <div class="text1">
                {{ i.title }}
                <span class="hot" v-if="i.isHot">HOT</span>
              </div>
              <div class="text2">{{ i.text }}</div>
            </div>
            <div class="tag">
              <div v-for="v2 in i.tagList" :key="v2" class="tag-1">
                {{ v2 }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 首页轮播图2 -->
      <div v-if="!type">
        <van-swipe class="my-swipe-1" indicator-color="white" autoplay="5000">
          <van-swipe-item v-for="item in homeContentList2" :key="item">
            <div class="banner">
              <img style="width: 100%" :src="item.img" alt="" />
              <div class="cont">
                <div class="text">
                  {{ item.text }}
                </div>
                <div class="content">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 关于我们内容2 -->
      <div
        v-if="type === 'gywm'"
        class="home-content2 mapp-bac mapp-publicpadding"
      >
        <div v-if="type === 'gywm'" style="height: 180px"></div>
        <div v-if="type === 'gywm'" class="slicehover">
          <div v-for="i in slicesHoverList" :key="i" class="slicehover1">
            <div class="left">
              <img style="width: 48px" :src="i.img" alt="" />
            </div>
            <div class="right">
              <div class="name">{{ i.name }}</div>
              <div class="text">{{ i.text }}</div>
            </div>
          </div>
        </div>
        <div class="mapp-publitext">
          {{ !type ? '覆盖全行业的云服务' : '牧云文化' }}
        </div>
        <div class="mapp-publicontent">
          <div
            @click="homeContentClick(index)"
            v-for="(i, index) in homeContentList2"
            :key="i"
            class="label-content"
          >
            <div class="label">
              <div class="label-content2-left">
                <span :class="i.active ? 'col' : ''">
                  {{ i.name }}
                </span>
              </div>
              <div v-show="!i.active" class="label-content2-right">
                <van-icon name="arrow-down" />
              </div>
            </div>
            <div v-if="i.active" class="label-content2">
              <div class="img"></div>
              <div class="label-content2-left">
                <img
                  style="width: 100%; height: 100%"
                  class="img2"
                  :src="homeContentList2[activeName].img"
                  alt=""
                />
              </div>
              <div style="position: relative; flex: 1">
                <div class="label-content2-right">
                  <div class="content">
                    <span>{{ homeContentList2[activeName].content }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 首页内容3 -->
      <!-- <div v-if="!type" class="home-content3 mapp-publicpadding">
        <div class="mapp-publitext">覆盖全行业的云服务</div>
        <div class="home-content3-link mapp-publicontent">
          <div class="linkimg" v-for="i in linkList" :key="i">
            <img style="width: 100%; height: 100%" :src="i.img" alt="" />
          </div>
        </div>
      </div> -->
    </div>
    <!-- 安华东莞数据中心 -->
    <div v-if="type === 'ahdgsjzx'" class="bannerAhSlice">
      <div class="img">
        <img
          style="width: 100%; height: 100%"
          :src="bannerAhSliceList[activeBanner].img"
          alt=""
        />
        <div class="ssimg">
          <div
            @click="activeBanner = index"
            :style="{
              border: activeBanner == index ? '2px solid #ffffff' : 'none',
            }"
            class="simg"
            v-for="(i, index) in bannerAhSliceList"
            :key="i"
          >
            <img
              class="simg1"
              style="width: 100%; height: 100%"
              :src="i.img"
              alt=""
            />
          </div>
        </div>
      </div>
      <div style="height: 27px" class="mc"></div>
      <div class="mcc mapp-publicpadding">
        <div class="mcc1">
          <img style="width: 38px; height: 38px" src="/img/IDC/ah.png" alt="" />
          <span class="mcc1-1">安华 (东莞) 数据中心</span>
        </div>
        <div class="mcc2">
          • 本项目位于东莞市麻涌镇新沙工业园区，广深沿江高速麻涌出口，
          距离深圳70分钟、距离广州天河区40分钟车程，交通便利。
          <br />
          • 总建筑面积: 74252.8平方米。
        </div>

        <div class="mcc3">
          <div style="margin-right: 19px">
            <img
              style="width: 9px; height: 14px"
              src="/img/cdn_sjx.png"
              alt=""
            />
          </div>
          <div>
            <div class="name">可容纳机柜</div>
            <div class="num">10000+</div>
          </div>
        </div>
        <img class="bacimg" src="/img/mapp/gywm.png" alt="" />
      </div>
    </div>
    <!-- 边缘云计算 广泛应用场景 -->
    <div class="gfyycj mapp-publicpadding mapp-bac" v-if="type === 'byyjs'">
      <div class="mapp-publitext">广泛的应用场景</div>
      <div class="home-content3-link mapp-publicontent">
        <div class="CDN2-left">
          <div class="bot">
            <span
              @click="gfyycjClick(index)"
              :class="
                gfyycjListactive == index ? 'botimg isActiveHover' : 'botimg'
              "
              v-for="(i, index) in gfyycjList"
              :key="i"
            >
              <span class="bimg">
                <img style="width: 24px" :src="i.img" alt="" />
              </span>
              <span class="text">
                {{ i.name }}
              </span>
            </span>
          </div>
        </div>
        <div class="CDN2-right">
          <van-image
            fit="cover"
            width="100%"
            height="100%"
            :src="gfyycjList[gfyycjListactive].bac"
          />
        </div>
        <div class="text">
          {{ gfyycjList[gfyycjListactive].text }}
        </div>
        <div class="title">推荐搭配</div>
        <div class="gfyycj-foot">
          <div
            class="gfyycj-foot-1"
            v-for="v in gfyycjList[gfyycjListactive].list1"
            :key="v"
          >
            {{ v.text }}
          </div>
        </div>
      </div>
    </div>
    <!-- 加入我们 -->
    <div
      v-if="type === 'gywm'"
      class="gywm-jrwm"
      style="height: calc(100vw * 1.45)"
    >
      <img
        class="img"
        style="width: 100%; height: 100%"
        src="/img/gywm_slices/jrwm.png"
        alt=""
      />
      <img class="logoImg" src="/img/logo.png" alt="加载失败" />
      <div class="bottom">
        <div class="text1">加入我们～</div>
        <div class="text1">
          我们正在寻找不平凡的你，一同奋战的伙伴，未来的中坚力量
        </div>
        <div class="text2" style="margin-top: 24px">
          联系方式：简历及作品请发送至邮箱：
        </div>
        <div class="text2" style="color: rgb(2, 139, 255)">
          hr@herdcloud.com
        </div>
        <div class="text2">邮件标题注明：“姓名+应聘岗位+手机号码”</div>
        <div class="text2">
          为了更加全面的了解您，建议您在附件中加上您的个人作品或参与的项目
        </div>
      </div>
    </div>
    <!-- 尾部背景行高-->
    <div
      v-if="type === 'gywm' || type === 'CDN'"
      style="height: 12px; background: #f5f7fa"
    ></div>
    <!-- 尾部 -->
    <div class="foot mapp-publicpadding">
      <div class="mapp-publitext" style="text-align: left">
        <img style="height: 32px" src="/img/logo.png" alt="" />
      </div>
      <div class="mapp-publicontent bor">
        <div class="foot-left-content" v-for="i in footAboutWeList" :key="i">
          <div class="foot-left-content-1">
            <img
              v-if="i.icon"
              :src="i.icon"
              alt=""
              style="margin-right: 8px; width: 16px; height: 16px"
            />{{ i.text }}
          </div>
          <div class="foot-left-content-2">
            <img
              v-if="i.icon2"
              :src="i.icon2"
              alt=""
              style="margin-right: 8px; width: 16px; height: 16px"
            />
            {{ i.text2 }}
          </div>
        </div>
      </div>
      <div class="end">
        <div class="end-1">2022 BASICFINDER</div>
        <div class="end-2">北京牧云时代科技有限公司 ALL RIGHTS RESERVED</div>
        <div class="end-3" @click="torecords">京ICP备18028165号-2</div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      serviceList: [
        {
          name: '产品与服务',
          active: true,
          list: [
            {
              name: '网络',
              contentList: [
                {
                  text: 'CDN',
                  src: '/img/slices_cd.png',
                  route: 'CDN',
                },
                {
                  text: 'SD-WAN',
                  src: '/img/slices_sd.png',
                  route: 'SD-WAN',
                },
              ],
            },
            {
              name: '边缘计算',
              contentList: [
                {
                  text: '裸金属云',
                  src: '/img/slices_lj.png',
                  route: 'byjsy',
                },
                {
                  text: 'KVM',
                  src: '/img/slices_kv.png',
                  route: 'KVM',
                },
                {
                  text: '容器云',
                  src: '/img/slices_rq.png',
                  route: 'byrqy',
                },
              ],
            },
            {
              name: 'IDC',
              contentList: [
                {
                  text: '安华 (东莞) 数据中心',
                  src: '/img/slices_ah.png',
                  route: 'ahdgsjzx',
                },
                {
                  text: '空港 (无锡) 大数据产业园',
                  src: '/img/slices_kg.png',
                  active: true,
                },
              ],
            },
            {
              name: '服务',
              contentList: [
                {
                  text: '边缘计算平台',
                  src: '/img/slices_by.png',
                  route: 'byjspt',
                },
              ],
            },
          ],
        },
        {
          active: false,
          name: '解决方案',
          list: [
            {
              name: '游戏',
              contentList: [
                {
                  text: '云游戏解决方案',
                  src: '/img/slices_yyx.png',
                  route: 'yyxjjfa',
                },
              ],
            },
          ],
        },
        {
          active: false,
          name: '关于我们',
          list: [],
          route: 'gywm',
        },
      ], //产品服务数据数组
      isPopupShow: false, //菜单弹出层
      type: null,
      bannerList: [
        {
          img: '/img/m_sy_slices/bysl1.jpg',
          imgtype: null,
          name: '边缘算力网络服务商',
          text: '牧云致力于打造国内优秀的边缘计算服务平台。为互联网、游戏等行业客户提供裸金属云、5G MEC高可用低延迟的边缘云服务',
          button: '了解详情',
          style: 'color:#fff',
          textstyle: 'color:#fff;opacity: 0.8;width:40%',
          tagList: ['裸金属云', '容器云', 'CDN', 'KVM'],
        },
        {
          img: '/img/m_sy_slices/nrff1.jpg',
          imgtype: null,
          name: '内容分发网络 CDN',
          text: '依托牧云全球边缘云架构，为企业提供全透明与定制化的内容分发服务，适用于网页加速、下载加速、视频加速等多种场景',
          button: '了解详情',
          style: 'color:#fff',
          textstyle: 'color:#fff;opacity: 0.8;width:40%',
          tagList: ['快速稳定', '智能化', '安全可靠'],
        },
        {
          img: '/img/m_sy_slices/byy1.jpg',
          imgtype: null,
          name: '边缘云计算',
          text: '牧云积累多年的数据库研发、搭建和维护经验，为您打造高可用、高性能、即开即用、弹性伸缩的云数据库服务',
          button: '了解详情',
          style: 'color:#fff',
          textstyle: 'color:#fff;opacity: 0.8;width:40%',
          tagList: ['弹性伸缩', '高性能', '高可用'],
        },
        {
          img: '/img/m_sy_slices/ahsj1.jpg',
          imgtype: null,
          name: '安华 (东莞) 数据中心',
          text: '本项目位于东莞市麻涌镇新沙工业园区， 总建筑面积: 74252.8平方米，可容纳机柜10000+',
          button: '了解详情',
          style: 'color:#fff',
          textstyle: 'color:#fff;opacity: 0.8;width:40%',
          tagList: [],
        },
      ],
      bannerSyCpList: [
        {
          img: '/img/banner/mapp_sy.png',
          imgtype: null,
        },
        {
          img: '/img/banner/mapp_CDN.png',
          name: 'CDN',
          text: '依托牧云全球边缘云架构，为企业提供全透明与定制化的内容分发服务，适用于网页加速、下载加速、视频加速等多种场景，协同企业需求共同发展。',
          button: '了解详情',
          imgtype: 'CDN',
        },
        {
          img: '/img/banner/mapp_ljsy.png',
          name: '边缘裸金属云',
          text: '为您提供云端独享的高性能、无虚拟化的、安全隔离的物理服务器集群。使用该服务，您只需根据业务特性弹性伸缩物理服务器数量，获取物理服务器的时间将被缩短至分钟级。将容量管理及运维工作交由牧云，您可专注于业务创新。',
          button: '马上体验',
          imgtype: 'byjsy',
        },
        {
          img: '/img/banner/mapp_byrqy.png',
          name: '边缘容器云',
          text: '基于原生 Kubernetes 提供以容器为核心的管理服务，完全兼容原生K8s，具有安全隔离，高资源利用率，秒级弹性，轻运维，灰度发布功能，支持统一编排，业务快速接入，能够帮助用户轻松实现云边一体化协同​。',
          button: '马上体验',
          imgtype: 'byrqy',
        },
        {
          img: '/img/banner/mapp_byjsy.png',
          name: '边缘云计算',
          text: '牧云积累多年的数据库研发、搭建和维护经验，为您打造高可用、高性能、即开即用、弹性伸缩的云数据库服务，拥有容灾、备份、恢复、安防、监控、迁移等全方位解决方案。',
          button: '马上体验',
          imgtype: 'byyjs',
        },
        {
          img: '/img/banner/mapp_gywm.png',
          name: '关于牧云时代',
          text: '北京牧云时代科技有限公司成立于2015年，提供基础数据汇总新服务、运营商增值服务、云网解决方案、云游戏解决方案、SAAS和边缘计算服务，致力于打造国内优秀的边缘算力网络服务提供商。为互联网、游戏、教育、交通等行业客户提供DC、裸金属云、5G MEC高可用高连接低延迟的边缘云服务',
          type: 1,
          imgtype: 'gywm',
        },
        {
          img: '/img/m_sy_slices/kvm.png',
          name: 'KVM',
          text: '云主机就像使用自己的本地PC或物理服务器一样，在云上使用云主机。您可以根据自己的需求指定CPU、内存、操作系统、规格、登录鉴权方式配备云主机，同时也可以随时调整云主机的规格，为您打造可靠、安全、灵活、高效的计算环境。',
          button: '马上体验',
          imgtype: 'KVM',
        },
        {
          img: '/img/m_sy_slices/SD-WAN.png',
          name: 'SD-WAN',
          text: 'SD-WAN 助力多分支轻松实现与云、数据中心的任意互联，具有即插即用、多地域覆盖、智能管控等特性，为企业多分支提供了更简单、可靠、智能的一站式上云的体验。',
          button: '马上体验',
          imgtype: 'SD-WAN',
        },
        {
          img: '/img/m_sy_slices/byjspt.png',
          name: '边缘计算平台',
          text: '边缘计算平台 为用户提供完整的“云边端”协同一体化服务，解决边缘节点按需接入、业务应用下沉部署、云边数据互传联动等云边协同问题，满足客户对边缘资源的远程管控、协同数据处理、智能化分析决策等需求。',
          button: '马上体验',
          imgtype: 'byjspt',
        },
        {
          img: '/img/m_sy_slices/yjjfa.png',
          name: '云游戏解决方案',
          text: '随着5G、光纤等高速网络的发展和普及，云游戏成为游戏行业的必然趋势；牧云凭借强大的云基础设施、云渲染及网络传输等核心技术支持，为游戏企业提供一站式云游戏平台方案，让玩家可在任何地方、任何设备、随时畅玩3A游戏。',
          button: '马上体验',
          imgtype: 'yyxjjfa',
        },
      ],
      homeContentList: [
        {
          title: 'CDN',
          isImg: true,
          isHot: true,
          text: '快速、稳定、智能、可靠的内容加速服务',
          tagList: ['快速稳定', '智能化', '安全可靠'],
          route: 'CDN',
        },
        {
          title: '边缘裸金属云',
          isHot: true,
          text: '高性能、无虚拟化的、安全隔离的物理服务器集群',
          tagList: ['弹性伸缩', '实时响应'],
          route: 'byjsy',
        },
        {
          title: '边缘容器云',
          text: '基于原生 Kubernetes 提供以容器为核心的管理服务',
          tagList: ['高扩展性', '高性能'],
          route: 'byrqy',
        },
        {
          title: 'SD-WAN',
          text: '多分支轻松实现与云、数据中心的任意互联',
          tagList: ['即插即用', '多地域覆盖', '智能管控'],
          route: 'SD-WAN',
        },
        {
          title: 'KVM',
          isHot: true,
          text: '高安全、高可靠、高弹性、灵活且易用的云计算服务',
          tagList: ['高性价比', '高安全', '高可靠'],
          route: 'KVM',
        },
        {
          title: '边缘计算平台',
          text: '远程管控、协同数据处理的云边端协同一体化服务',
          tagList: ['协同数据处理', '智能化分析决策'],
          route: 'byjspt',
        },
      ], //主页内容1
      homeContentList2: [
        {
          name: '自动驾驶',
          img: '/img/sy_hyt/zdm.jpg',
          text1: '自动驾驶',
          text2: 'Autopilot',
          text: 'AUTOPILOT 自动驾驶解决方案',
          content:
            '未来自动驾驶汽车传感器产生的大量数据离不开云服务。汽车自动驾驶云服务将变成一个信息、数据的采集工具，车辆将收集的成像、天气、路况、交通运动、行人等数据信息回传到云端进行存储、深度分析，从而通过远程升级为汽车带来新的能力，通过这样的循环可以打造更安全的自动驾驶。',
          active: true,
        },
        {
          name: '金融行业',
          img: '/img/sy_hyt/jrm.jpg',
          text1: '金融行业',
          text2: 'Finance',
          text: 'FINANCE 金融行业解决方案',
          content:
            '金融行业头部机构通过行业云的方式做业务、科技的输出,带动整个行业共同发展近期成为金融机构科技创新的一个重要方向。其对数据中心的安全性及合规性有着较高要求，从而为金融客户单独建立满足需求的数据中心。该数据处理中心承载着金融系统的存储和计算负荷，必须具备海量数据存储能力及大规模数据处理能力，能够支撑金融客户需求。',
          active: false,
        },
        {
          name: '医疗行业',
          img: '/img/sy_hyt/ylm.jpg',
          text1: '医疗行业',
          text2: 'medical treatment',
          text: 'MEDICAL TREATMENT 医疗行业解决方案',
          content:
            '随着云以指数级别接管技术，医疗行业的云计算潜力巨大。由于云提供按需计算，它已迅速成为首选工具，尤其是当医疗保健机构和医院需要随时部署，访问和处理网络信息时。随着医疗保健法规推动医疗保健行业寻求更好的存储，协作和数据共享技术，迫切需要防止任何数据丢失。由于电子病历（EMR）容易导致数据丢失，因此云已被证明是一种可靠，安全的数据存储介质，可促进更好的协作，同时提供对安全存储的访问并提供对存储数据的远程访问。',
          active: false,
        },
        {
          name: '智能安防',
          img: '/img/sy_hyt/znm.jpg',
          text1: '智能安防',
          text2: 'Intelligent security',
          text: 'INTELLIGENT SECURITY 智能安防解决方案',
          content:
            '在智能监控的领域中，无论是在那个方面监控，监控的存储都是采用的是磁盘阵列，硬盘，SD卡等进行存储。客户端与设备端进行通讯大多都是通过服务器或客户端获取设备数据播放。在提供一种基于云端实现智能安防的方法，解决现有技术中读写不稳定、无法分析处理数据、使用范围小的问题。',
          active: false,
        },
        {
          name: '公共服务',
          img: '/img/sy_hyt/ggm.jpg',
          text1: '公共服务',
          text2: 'Public services',
          text: 'PUBLIC SERVICES 公共服务解决方案',
          content:
            '一种社区公共服务云系统平台，通过建立包容的“公共系统云”，创建有远程和近程良好通信信道的“公共系统云”、“社区装置”、“服务装置”构成的系统，充分使用手机APP软件的功能，为没有能力独立建设高要求体系的社区提供了无人化付费服务的托管管理,提供了系统服务的公共服务平台和方法，达到了非常方便实用的要求，可以广泛用于社区有付费服务的社区应用。',
          active: false,
        },
        {
          name: '电子商务',
          img: '/img/sy_hyt/dzm.jpg',
          text1: '电子商务',
          text2: 'E-commerce',
          text: 'E-COMMERCE 电子商务行业解决方案',
          content:
            '电子商务企业选择云服务可以根据自身业务需求变化而对资源进行弹性扩展，最大程度上避免资源浪费，降低成本。基于云服务原理的平台，可以解决数据处理问题，从而带来一体化、专业化服务，同时也提高了企业发展的灵活性。',
          active: false,
        },
        {
          name: '互联网行业',
          img: '/img/sy_hyt/hlm.jpg',
          text1: '互联网行业',
          text2: 'Internet',
          text: 'INTERNET 互联网行业解决方案',
          content:
            '云服务作为未来的发展趋势，将深刻影响互联网行业，并驱使大大小小的互联网企业进行创新与转型。',
          active: false,
        },
      ],
      activeName: 0, //首页内容2 标签切换
      linkList: [
        {
          href: '',
          img: '/img/m_sy_slices/txy.png',
        },
        {
          href: '',
          img: '/img/m_sy_slices/zgyd.png',
        },
        {
          href: '',
          img: '/img/m_sy_slices/ks.png',
        },
        {
          href: '',
          img: '/img/m_sy_slices/blbl.png',
        },
        {
          href: '',
          img: '/img/m_sy_slices/jsy.png',
        },
        {
          href: '',
          img: '/img/m_sy_slices/byskj.png',
        },
        {
          href: '',
          img: '/img/m_sy_slices/sjy.png',
        },
        {
          href: '',
          img: '/img/m_sy_slices/xcy.png',
        },
      ],
      footAboutWeList: [
        {
          text: '企业总机',
        },
        {
          icon: '/img/dh.png',
          text: '010-64370279',
        },
        {
          text: '业务咨询',
          text2: '品牌公关',
        },
        {
          icon: '/img/dh.png',
          text: '+86 186-1526-7925',
          icon2: '/img/dh.png',
          text2: '+86 189-1043-3652',
        },
        {
          icon: '/img/yx.png',
          text: 'sales@herdcloud.com',
          icon2: '/img/yx.png',
          text2: 'pr@herdcloud.com',
        },
      ],
      publicImgObj: {},
      cdnlist: [
        {
          name: '更快速',
          img: '/img/cdn_ks.png',
          text: '牧云自建高质量节点遍布全国，覆盖全国30多个省市；10线+运营商网络接入，Tb级别带宽承载，覆盖所有运营商；单节点带宽不低于40Gbps，业务平均98%+流量命中率；毫秒级别响应时间；中心节点收敛回源。',
        },
        {
          name: '更稳定',
          img: '/img/cdn_wd.png',
          text: '服务可用性高达99.95%；企业级WAF、DDoS防御机制；支持全网HTTPS安全传输，网站防盗链等高级安全控制功能；实时数据采集，帮助您有效监控业务运行情况。',
        },
        {
          name: '更优惠',
          img: '/img/cdn_yh.png',
          text: '支持按日峰值带宽、按流量计费；按需付费，用多少计多少；月带宽峰值在2Gbps以上的客户可以通过工单申请按月计费商务洽谈。',
        },
        {
          name: '更便捷',
          img: '/img/cdn_bj.png',
          text: '接入方式简单快速，提供自助化的域名管理，并且支持多种可定制配置项，方便客户进行统计分析、日志管理；支持平台化接入，自助配置加速；配置项丰富，支持缓存策略、缓存Key计算、回源、视频、防盗链、HTTPS等相关的配置项；一键刷新缓存。',
        },
        {
          name: '更专业',
          img: '/img/cdn_zy.png',
          text: '全方位实时业务监控，支持分钟级访问/回源流量、带宽、请求数、命中率、各具体状态码数据监控，您可实时了解 CDN 服务状态，辅助运维人员监测源站状态；也可前往云监控按需配置告警策略，及时掌握业务波动。',
        },
        {
          name: '更全面',
          img: '/img/cdn_qm.png',
          text: '遍布全球50+国家与地区，全网带宽120Tbps+。中国境内含2000+加速节点，覆盖移动、联通、电信及十几家中小型运营商，中国境外含1000+加速节点。为各类型业务保驾护航，提升服务可用性。',
        },
      ],
      cdnlisttype: false,
      cdnBotList: [
        {
          name: '点播加速',
          img: '/img/cdn_yycj_dbjs.png',
          obj: {
            bac: '/img/ywjgt_slices/dbbac.png',
            text: '牧云CDN支持在线点播加速业务，如mp4、flv视频文件的点播加速。主要的业务场景是视频点播，建议可以搭配对象存储BOS服务，可提升回源速度，节约近回源带宽成本。',
            img: '/img/ywjgt_slices/db.png',
          },
          active: true,
        },
        {
          name: '网络加速',
          img: '/img/cdn_yycj_wljs.png',
          obj: {
            bac: '/img/ywjgt_slices/wlbac.png',
            text: '牧云CDN支持在线点播加速业务，如mp4、flv视频文件的点播加速。主要的业务场景是视频点播，建议可以搭配对象存储BOS服务，可提升回源速度，节约近回源带宽成本。',
            img: '/img/ywjgt_slices/wl.png',
          },
          active: false,
        },
        {
          name: '超大文件下载',
          img: '/img/cdn_yycj_cdwjxz.png',
          obj: {
            bac: '/img/ywjgt_slices/cdwjbac.png',
            text: '牧云CDN支持在线点播加速业务，如mp4、flv视频文件的点播加速。主要的业务场景是视频点播，建议可以搭配对象存储BOS服务，可提升回源速度，节约近回源带宽成本。',
            img: '/img/ywjgt_slices/cdwj.png',
          },
          active: false,
        },
        {
          name: '音频加速',
          img: '/img/cdn_yycj_ypjs.png',
          obj: {
            bac: '/img/ywjgt_slices/ypbac.png',
            text: '牧云CDN支持在线点播加速业务，如mp4、flv视频文件的点播加速。主要的业务场景是视频点播，建议可以搭配对象存储BOS服务，可提升回源速度，节约近回源带宽成本。',
            img: '/img/ywjgt_slices/yp.png',
          },
          active: false,
        },
        {
          name: '动态加速',
          img: '/img/cdn_yycj_dtjs.png',
          obj: {
            bac: '/img/ywjgt_slices/dtbac.png',
            text: '牧云CDN支持在线点播加速业务，如mp4、flv视频文件的点播加速。主要的业务场景是视频点播，建议可以搭配对象存储BOS服务，可提升回源速度，节约近回源带宽成本。',
            img: '/img/ywjgt_slices/dt.png',
          },
          active: false,
        },
      ],
      typePosition: 0,
      cdnDtList: [
        {
          img: '/img/mapp/qq.png',
          list: [
            {
              name: '国家地区',
              num: '50+',
            },
            {
              name: '海外节点',
              num: '200+',
            },
            {
              name: '带宽储备',
              num: '100T',
            },
          ],
        },
        {
          img: '/img/mapp/gn.png',
          list: [
            {
              name: '覆盖全国省市',
              num: '30+',
            },
            {
              name: '国内节点',
              num: '1000+',
            },
            {
              name: '多线路',
              num: '电信、联通、移动、广电、铁通、鹏博士、华数、BGP等',
              size: 'font-size:14px',
            },
          ],
        },
      ],
      cdnObj: {},
      activeBanner: 0,
      bannerAhSliceList: [
        {
          img: '/img/IDC/1.png',
        },
        {
          img: '/img/IDC/2.png',
        },
        {
          img: '/img/IDC/3.png',
        },
        {
          img: '/img/IDC/4.png',
        },
      ],
      gfyycjList: [
        {
          name: '金融',
          engname: 'Finance',
          bac: '/img/byy_yycjt/1.jpg',
          img: '/img/mapp/m_byyjs_slices/jr.png',
          text: '金融行业对于数据安全和可靠性有非常严格的要求，RDS既拥有商业数据库的稳定可靠性，又拥有开源数据库的灵活性和低成本',
          list1: [
            {
              text: 'ECS',
            },
            {
              text: 'SLB',
            },
            {
              text: 'DDOS',
            },
            {
              text: '云数据库MySQL',
            },
            {
              text: '云数据库Redis',
            },
          ],
          active: true,
        },
        {
          name: '游戏',
          engname: 'Games',
          bac: '/img/byy_yycjt/2.jpg',
          img: '/img/mapp/m_byyjs_slices/yx.png',
          text: '游戏公司为了快速抢占市场.需要快速的开发出新产品吸引玩家，云数据库 Redis版能减少系统开发复杂度，业务爆发时可轻松弹性扩容，满足高性能业务要求',
          list1: [
            {
              text: 'ECS',
            },
            {
              text: 'SLB',
            },
            {
              text: 'DDOS',
            },
            {
              text: '云数据库MySQL',
            },
            {
              text: '云数据库Redis',
            },
          ],
          active: false,
        },
        {
          name: '电商',
          engname: 'E-commerce',
          bac: '/img/byy_yycjt/3.jpg',
          img: '/img/mapp/m_byyjs_slices/ds.png',
          text: '轻松应对高并发的应用场景，在促销、秒杀等流量峰值的场景中实现秒级扩容，支持企业应对大规模数据分析的读写需求。实现海量数据低成本存储、快速弹性扩容，保障数据库集群可用性',
          list1: [
            {
              text: 'ECS',
            },
            {
              text: 'SLB',
            },
            {
              text: '云数据库PolarDB',
            },
            {
              text: '云数据库Redis',
            },
          ],
          active: false,
        },
        {
          name: '互联网',
          engname: 'Internet',
          bac: '/img/byy_yycjt/4.jpg',
          img: '/img/mapp/m_byyjs_slices/hlw.png',
          text: '互联网行业的发展经常呈爆发性增长，业务波动变化频繁，流量高峰难以预测。RDS弹性扩展能力至关也要，凭借其强大的弹性能力使得它特别契合这一行业特点',
          list1: [
            {
              text: 'ECS',
            },
            {
              text: 'SLB',
            },
            {
              text: '云数据库MySQL',
            },
            {
              text: '云数据库MongoDB版',
            },
            {
              text: '云数据库Redis',
            },
          ],
          active: false,
        },
      ],
      gfyycjListactive: 0,
      slicesHoverByjsyList: [
        {
          img: '/img/mapp/m_byyjs_slices/byr.png',
          name: '边缘容器云',
          text: '基于原生 Kubernetes 提供以容器为核心的管理服务，能够帮助用户轻松实现云边一体化协同​。',
        },
        {
          img: '/img/mapp/m_byyjs_slices/byj.png',
          name: '边缘裸金属云',
          text: '为您提供云端独享的高性能、无虚拟化的、安全隔离的物理服务器集群。',
        },
      ],
      slicesHoverList: [
        {
          img: '/img/gywm_slices/yj.png',
          name: '愿景',
          text: '成为一个优秀的算力网络服务商',
        },
        {
          img: '/img/gywm_slices/sm.png',
          name: '使命',
          text: '连接元宇宙，助力数字化',
        },
      ],
      activeKvmName: 0,
      kvmContentList: [
        {
          name: '计算型',
          active: true,
          list: [
            {
              isHot: true,
              name: '计算型c3',
              text: '搭载高基准主频的处理器，提供更稳定、高性能的计算能力',
              gnList: [
                {
                  title: 'CPU内存比',
                  text: '1:4',
                },
                {
                  title: 'CPU类型',
                  text: 'Intel® Xeon® Gold 6248R',
                },
                {
                  title: '主频',
                  text: '3.0 GHz',
                },
                {
                  title: '最大内网带宽',
                  text: '18 Gbit/s',
                },
              ],
              cjObj: {
                name: '适用场景',
                cjList: [
                  {
                    text: '高性能Web前端服务器',
                  },
                  {
                    text: '批处理工作负载',
                  },
                  {
                    text: '视频编解码',
                  },
                ],
              },
            },
            {
              isHot: true,
              name: '计算网络优化型c3ni',
              text: '物理机配备25GE网卡，在计算型基础上，提供更高的内网性能，拥有更大带宽、更低时延',
              gnList: [
                {
                  title: 'CPU内存比',
                  text: '1:4',
                },
                {
                  title: 'CPU类型',
                  text: 'Intel® Xeon® Gold 6248R',
                },
                {
                  title: '主频',
                  text: '3.0 GHz',
                },
                {
                  title: '最大内网带宽',
                  text: '30 Gbit/s',
                },
              ],
              cjObj: {
                name: '适用场景',
                cjList: [
                  {
                    text: '高性能Web前端服务器',
                  },
                  {
                    text: '多人联机游戏服务器',
                  },
                  {
                    text: '高网络包收发场景',
                  },
                ],
              },
            },
          ],
        },
        {
          name: '内存型',
          active: false,
          list: [
            {
              isHot: false,
              name: '内存优化型m4',
              text: '新一代内存优化型产品，适用于对内存要求高，数据量大并且数据访问量大的应用',
              gnList: [
                {
                  title: 'CPU内存比',
                  text: '1:8',
                },
                {
                  title: 'CPU类型',
                  text: 'Intel® Xeon® Gold 6248R',
                },
                {
                  title: '主频',
                  text: '3.0 GHz',
                },
                {
                  title: '最大内网带宽',
                  text: '20 Gbit/s',
                },
              ],
              cjObj: {
                name: '适用场景',
                cjList: [
                  {
                    text: '内存数据库',
                  },
                  {
                    text: '大数据处理引擎',
                  },
                ],
              },
            },
            {
              isHot: false,
              name: '内存优化型m3',
              text: '更大的vCPU/内存比，快速的数据交换和处理能力，适用于内存要求高，数据量大并且数据访问量大的应用',
              gnList: [
                {
                  title: 'CPU内存比',
                  text: '1:4',
                },
                {
                  title: 'CPU类型',
                  text: 'Intel® Xeon® Gold 6248',
                },
                {
                  title: '主频',
                  text: '2.5 GHz',
                },
                {
                  title: '最大内网带宽',
                  text: '15 Gbit/s',
                },
              ],
              cjObj: {
                name: '适用场景',
                cjList: [
                  {
                    text: '内存数据库',
                  },
                  {
                    text: '分布式缓存系统',
                  },
                ],
              },
            },
            {
              isHot: false,
              name: '超大内存型em3',
              text: '提供超大内存配置，同时配置高计算、存储、网络能力。适用于大量的内存操作、查找和计算的应用',
              gnList: [
                {
                  title: 'CPU内存比',
                  text: '1:16',
                },
                {
                  title: 'CPU类型',
                  text: 'Intel® Xeon® Gold 6248R',
                },
                {
                  title: '主频',
                  text: '3.0 GHz',
                },
                {
                  title: '最大内网带宽',
                  text: '30 Gbit/s',
                },
                {
                  title: '最大内存',
                  text: '1280 G',
                },
              ],
              cjObj: {
                name: '适用场景',
                cjList: [
                  {
                    text: '分布式内存缓存',
                  },
                  {
                    text: '大数据处理引擎',
                  },
                  {
                    text: '高性能数据库',
                  },
                ],
              },
            },
          ],
        },
        {
          name: '大数据型',
          active: false,
          list: [
            {
              isHot: false,
              name: '大数据型d3',
              text: '通过直通模式搭载大容量本地数据盘，多盘并发顺序写性能与单盘性能一致 ，缩短大数据分布式文件读取和写入时间',
              gnList: [
                {
                  title: 'CPU内存比',
                  text: '1:4',
                },
                {
                  title: 'CPU类型',
                  text: 'Intel® Xeon® Gold 6248',
                },
                {
                  title: '主频',
                  text: '2.5 GHz',
                },
                {
                  title: '最大内网带宽',
                  text: '30 Gbit/s',
                },
                {
                  title: '数据盘',
                  text: 'SATA HDD',
                },
                {
                  title: '最大单盘吞吐',
                  text: '250 MB/s',
                },
              ],
              cjObj: {
                name: '适用场景',
                cjList: [
                  {
                    text: '分布式文件存储 (HDFS)',
                  },
                  {
                    text: '并行计算 (MapReduce)',
                  },
                  {
                    text: 'ElasticSearch、日志等',
                  },
                ],
              },
            },
          ],
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    $route(val) {
      let { path } = val;
      this.type = path.split('/')[1];
      this.cdnlisttype = false;
      this.isPopupShow = false;
      this.activeName = 0;
      // console.log(this.type, '手机端')
      this.publicImgObj = {};
      [...this.bannerList, ...this.bannerSyCpList].forEach((res) => {
        if (res.imgtype === this.type) {
          this.publicImgObj = res;
        }
      });
      if (this.type === 'CDN') {
        this.cdnBotList = [
          {
            name: '点播加速',
            img: '/img/cdn_yycj_dbjs.png',
            obj: {
              bac: '/img/ywjgt_slices/dbbac.png',
              text: '• 牧云CDN支持在线点播加速业务，如mp4、flv视频文件的点播加速。主要的业务场景是视频点播，建议可以搭配对象存储BOS服务，可提升回源速度，节约近回源带宽成本。',
              img: '/img/ywjgt_slices/db.png',
            },
            active: true,
          },
          {
            name: '网络加速',
            img: '/img/cdn_yycj_wljs.png',
            obj: {
              bac: '/img/ywjgt_slices/wlbac.png',
              text: '• 牧云CDN支持不同粒度文件全国分发加速，解决了在线游戏、音乐、视频、软件等大型文件传输慢及传输不稳定的问题。',
              img: '/img/ywjgt_slices/wl.png',
            },
            active: false,
          },
          {
            name: '超大文件下载',
            img: '/img/cdn_yycj_cdwjxz.png',
            obj: {
              bac: '/img/ywjgt_slices/cdwjbac.png',
              text: '• 适用于以大文件下载为主的网站或应用，如游戏安装包下载更新、APK下载、音视频下载等。通过稳定、快速地下载加速，减少用户下载或更新的等待时间，提升用户转化率和留存率。',
              img: '/img/ywjgt_slices/cdwj.png',
            },
            active: false,
          },
          {
            name: '音频加速',
            img: '/img/cdn_yycj_ypjs.png',
            obj: {
              bac: '/img/ywjgt_slices/ypbac.png',
              text: '• 针对在线音视频播放业务场景，依托牧云多年在线视频运营经验，支撑高峰期海量并发，有效保证服务的高可用性和媒体传输速度，提供稳定、流畅、丰富的观看体验。',
              img: '/img/ywjgt_slices/yp.png',
            },
            active: false,
          },
          {
            name: '动态加速',
            img: '/img/cdn_yycj_dtjs.png',
            obj: {
              bac: '/img/ywjgt_slices/dtbac.png',
              text: '• 针对门户网站、电商、UGC 社区等业务场景，提供了强大的静态内容（如网页样式、图片、小文件）加速分发处理能力，显著提升了网页用户的体验。',
              img: '/img/ywjgt_slices/dt.png',
            },
            active: false,
          },
        ];
        this.cdnlist = [
          {
            name: '更快速',
            img: '/img/cdn_ks.png',
            text: '牧云自建高质量节点遍布全国，覆盖全国30多个省市；10线+运营商网络接入，Tb级别带宽承载，覆盖所有运营商；单节点带宽不低于40Gbps，业务平均98%+流量命中率；毫秒级别响应时间；中心节点收敛回源。',
          },
          {
            name: '更稳定',
            img: '/img/cdn_wd.png',
            text: '服务可用性高达99.95%；企业级WAF、DDoS防御机制；支持全网HTTPS安全传输，网站防盗链等高级安全控制功能；实时数据采集，帮助您有效监控业务运行情况。',
          },
          {
            name: '更优惠',
            img: '/img/cdn_yh.png',
            text: '支持按日峰值带宽、按流量计费；按需付费，用多少计多少；月带宽峰值在2Gbps以上的客户可以通过工单申请按月计费商务洽谈。',
          },
          {
            name: '更便捷',
            img: '/img/cdn_bj.png',
            text: '接入方式简单快速，提供自助化的域名管理，并且支持多种可定制配置项，方便客户进行统计分析、日志管理；支持平台化接入，自助配置加速；配置项丰富，支持缓存策略、缓存Key计算、回源、视频、防盗链、HTTPS等相关的配置项；一键刷新缓存。',
          },
          {
            name: '更专业',
            img: '/img/cdn_zy.png',
            text: '全方位实时业务监控，支持分钟级访问/回源流量、带宽、请求数、命中率、各具体状态码数据监控，您可实时了解 CDN 服务状态，辅助运维人员监测源站状态；也可前往云监控按需配置告警策略，及时掌握业务波动。',
          },
          {
            name: '更全面',
            img: '/img/cdn_qm.png',
            text: '遍布全球50+国家与地区，全网带宽120Tbps+。中国境内含2000+加速节点，覆盖移动、联通、电信及十几家中小型运营商，中国境外含1000+加速节点。为各类型业务保驾护航，提升服务可用性。',
          },
        ];
      }
      if (this.type === 'byjsy') {
        this.cdnBotList = [
          {
            name: '大数据',
            img: '/img/ywjgt_slices/dsjimg.png',
            obj: {
              bac: '/img/ywjgt_slices/dsjbac.png',
              text: '• 基于 Apache Hadoop 框架搭建统一的大数据和计算平台，对海量数据进行采集、存储、分析、搜索、挖掘，发现其内在价值，适用于历史数据统计、交互式查询、精准营销、欺诈检测、社交网络分析等。\n\n• 牧云裸金属服务器提供单台12T（SATA）*12的大容量服务器，满足海量存储与高速读写的需求；采用双 bonding 的25G网络，满足海量数据交换需求。\n\n• 安全灵活的 VPC 网络让您自定义逻辑隔离的网络空间，使业务部署更安全，更可靠。',
              img: '/img/ywjgt_slices/dsj.png',
            },
            active: true,
          },
          {
            name: '虚拟化',
            img: '/img/ywjgt_slices/xnhimg.png',
            obj: {
              bac: '/img/ywjgt_slices/xnhbac.png',
              text: '• 越来越多的企业利用在 Openstack 上构建虚拟化（例如：KVM，Docker 等）私有云平台，把 IT 基础架构向资源共享方向发展，解决共享资源的环境下业务高峰期性能瓶颈问题，提高企业或组织 IT 基础架构业务支撑灵活度，降低系统管理复杂性。\n\n• 通过牧云自研虚拟化技术，支持嵌套虚拟化，给虚拟化平台提供最大自由性，是搭建云端搭建专有云虚拟化平台的最佳选择。\n\n• VPC 网络让您灵活地自定义网络空间，满足虚拟化的多网络平面需求。',
              img: '/img/ywjgt_slices/xnh.png',
            },
            active: false,
          },
          {
            name: '游戏',
            img: '/img/ywjgt_slices/yximg.png',
            obj: {
              bac: '/img/ywjgt_slices/yxbac.png',
              text: '• MMORPG（大型多人在线角色扮演游戏）画面绚丽、场面宏大、玩法和场景多样，深受玩家喜爱。尤其是大规模的跨服活动吸引众多玩家的加入，同一区域下所有玩家都相互可见，每位玩家的操作都要视野内广播，大量玩家的加入对接入服务器负载、稳定性和网络都有着极高的要求。\n\n• 游戏应用生命周期短，运营高峰时需有大量服务器投入运营，可依靠腾讯云庞大的资源池实现快速扩容；生命周期未端则面临裁撤和贬值等问题，利用牧云裸金属云按需购买、按量付费的特性，可改变游戏公司的成本结构以降低 TCO（总体拥有成本）。\n\n• 购买高性能牧云裸金属云部署战斗逻辑模块，利用裸机性能应对高 I/O、高 PPS 需求。',
              img: '/img/ywjgt_slices/yx.png',
            },
            active: false,
          },
        ];
        this.cdnlist = [
          {
            name: '极致计算和网络性能',
            img: '/img/byjsy_slices/jz.png',
            text: '兼具了虚拟机弹性与物理机性能的优势，实现了物理机极致的计算性能，并且可以支持再次虚拟化，而无需额外的嵌套虚拟化性能开销。网络层面通过独有的网络转发技术实现了高达千万级 pps 的转发性能。',
          },
          {
            name: '一键式交付',
            img: '/img/byjsy_slices/yj.png',
            text: '无需关心机房、网络和部署、监控等基础设施和软件环境，可在分钟级一键式获得标准的物理服务器集群。操作系统、磁盘分区、本地 RAID 都将自动化部署和划分，监控和安全能力都将直接具备，内外网服务都将直接配置。',
          },
          {
            name: '费用低廉',
            img: '/img/byjsy_slices/fy.png',
            text: '具备虚拟机弹性的牧云裸金属服务器让您无需一次性投入大量现金流购置机房和网络等基础设备。完善、快捷的监控、故障维修等服务，极大降低了维护的人力和金钱成本。',
          },
          {
            name: '完全兼容VPC网络',
            img: '/img/byjsy_slices/wq.png',
            text: '牧云裸金属服务器完全运行在公有云 VPC 网络里，您可以根据业务需求规划 VPC 网络，切实保证您云上资源的使用便捷性和安全性。',
          },
          {
            name: '免运维',
            img: '/img/byjsy_slices/myw.png',
            text: '牧云智能云提供7*24小时的运维服务，借助先进的监控与故障诊断技术，能自动发现服务器的主流故障，并在及时地自动触发修复流程，通过远程管控平台，自动调度 IDC 现场以及厂商资源，恢复服务器至可用状态。',
          },
          {
            name: '服务集成',
            img: '/img/byjsy_slices/fwjc.png',
            text: '完全融入牧云云产品体系，比如对象存储 COS、云数据库等；还可以和 PaaS 服务结合，比如 EMR 和 TKE 等，为您提供满足不同工作负载的完整的解决方案。',
          },
        ];
      }
      if (this.type === 'byrqy') {
        this.cdnBotList = [
          {
            name: 'CDN',
            img: '/img/ywjgt_slices/cdnimg.png',
            obj: {
              bac: '/img/ywjgt_slices/cdnbac.png',
              text: '• 提供海量边缘基础设施节点管理，cdn业务全生命周期管理能力；并提供标准k8s api助力构建CDN领域PaaS。',
              img: '/img/ywjgt_slices/cdn.png',
            },
            active: true,
          },
          {
            name: '边缘AI',
            img: '/img/ywjgt_slices/byimg.png',
            obj: {
              bac: '/img/ywjgt_slices/bybac.png',
              text: '• 边缘计算云原生架构为边缘智能应用提供完整的边缘应用、资源的生命周期管理、安全运行支撑、容灾容错保障、远程运维监控与报警等能力。提供云端AI能力服务下沉通道。',
              img: '/img/ywjgt_slices/by.png',
            },
            active: false,
          },
        ];
        this.cdnlist = [
          {
            name: '高效部署',
            img: '/img/byrqy_slices/gxbs.png',
            text: '运行在高品质的 BGP 网络中，能够实现镜像极速下载和上传，海量容器秒级启动，提高容器部署效率；可对提交的业务代码进行快速构建、测试和打包集成，将集成的代码部署到预发布环境和现网环境上。',
          },
          {
            name: '安全可靠',
            img: '/img/byrqy_slices/aqkk.png',
            text: '集群运行在私有网络，支持自定义安全组和网络 ACL。采用分布式服务架构，实现服务故障自动修复、数据快速迁移；结合有状态服务后端的分布式存储，实现高可用服务和数据的安全。',
          },
          {
            name: '简单易用',
            img: '/img/byrqy_slices/jjyy.png',
            text: '无需再使用集群管理软件和设计容错集群架构，便可简化大规模集群管理和分布式应用的管理、运维。只需启动容器集群，并指定运行的任务，即可完成所有的集群管理工作。',
          },
          {
            name: '灵活扩展',
            img: '/img/byrqy_slices/lhkz.png',
            text: '支持灵活集群托管，便于安排长期运行的应用程序和批量作业，以便扩展您的业务。支持在多个容器之间分配流量，自动恢复运行状况不佳的容器，保证容器数量满足您的需求。',
          },
          {
            name: '云边协同',
            img: '/img/byrqy_slices/ybxt.png',
            text: '边缘K8s管控云端托管，云边一体协同，边缘计算和云服务打通。',
          },
          {
            name: '高利用率',
            img: '/img/byrqy_slices/glyl.png',
            text: '命名空间级隔离，单个节点可承载上百个应用，极大的提高了资源利用率。',
          },
        ];
      }
      if (this.type === 'byyjs') {
        this.cdnlist = [
          {
            name: '易于使用的托管部署',
            img: '/img/byyjs_slices/yy.png',
            text: '只需在牧云管理控制台中单击几下，即可在几分钟内启动并连接到一个可以立即投入生产的MySQL数据库。云数据库MySQL数据库实例针对您选择的服务器类型预配置了各种参数和设置。数据库参数组可以提供对MySQL数据库的精细控制和微调功能。',
          },
          {
            name: '专项内核优化',
            img: '/img/byyjs_slices/zx.png',
            text: '牧云提供业界领先的IO吞吐能力；深度定制开发MySQL内核，性能远超基于开源MySQL的自建数据库。云数据库MySQL的性能优势让您可以以更少的数据库数量支撑更高的业务并发请求量，简化了后端架构，使得整体架构更易于管理和运维。',
          },
          {
            name: '完善的保障机制',
            img: '/img/byyjs_slices/ws.png',
            text: '牧云提供99.9996%的数据可靠性和99.95%的服务可用性，拥有完善的数据自动备份和无损恢复机制。牧云的高可靠性让您可以放心将数据放在云端，无需担心数据丢失，也简化了传统运维工作中为保障数据高可靠带来的额外工作量和额外的IT投入成本。',
          },
          {
            name: '强同步复制',
            img: '/img/byyjs_slices/qt.png',
            text: '支持强同步和半同步的数据复制方式。云数据库MySQL内核进行了多项数据复制优化，极大提高了数据强同步复制性能。同时优化了主备切换引起的幻读现象以及主机宕机恢复时导致的临界事务，大幅提高了MySQL5.6版本下的数据一致性。',
          },
          {
            name: '全面的日常监控',
            img: '/img/byyjs_slices/qm.png',
            text: '您可以在云数据库MySQL管理控制台中查看覆盖连接访问、数据库负载、查询缓存、存储引擎等七十余项重要指标，可全方位监控运行状况。自定义监控视图，在多实例同时间段间，或同实例不同时间段间多维度对比实例运行负载情况，迅速识别运行异常的数据库。',
          },
          {
            name: '自定义告警',
            img: '/img/byyjs_slices/zd.png',
            text: '自定义资源阈值告警，可帮助用户知晓云数据库MySQL运行中的问题。它将问题及时反馈给运维人员，帮助您快速响应数据库问题。',
          },
        ];
      }
      if (this.type === 'gywm') {
        this.homeContentList2 = [
          {
            img: '/img/gywm_wh/w.jpg',
            name: '服务决定下限',
            content:
              '强化服务意识，提高服务能力，永远把服务好客户作为牧云的首要目标。',
            active: true,
          },
          {
            img: '/img/gywm_wh/r.jpg',
            name: '认知决定高度',
            content:
              '牧云要求员工要不断学习，提高自己的认知能力，做到与时俱进。',
            active: false,
          },
          {
            img: '/img/gywm_wh/g.jpg',
            name: '做一个健康有趣的人',
            content:
              '牧云在倡导大家养成运动习惯的同时，也希望在漫长的人生旅途中能够和有趣的人同行。',
            active: false,
          },
        ];
      }
      if (!this.type) {
        this.homeContentList2 = [
          {
            name: '自动驾驶',
            img: '/img/sy_hyt/zdm.jpg',
            text1: '自动驾驶',
            text2: 'Autopilot',
            text: 'AUTOPILOT 自动驾驶解决方案',
            content:
              '未来自动驾驶汽车传感器产生的大量数据离不开云服务。汽车自动驾驶云服务将变成一个信息、数据的采集工具，车辆将收集的成像、天气、路况、交通运动、行人等数据信息回传到云端进行存储、深度分析，从而通过远程升级为汽车带来新的能力，通过这样的循环可以打造更安全的自动驾驶。',
            active: true,
          },
          {
            name: '金融行业',
            img: '/img/sy_hyt/jrm.jpg',
            text1: '金融行业',
            text2: 'Finance',
            text: 'FINANCE 金融行业解决方案',
            content:
              '金融行业头部机构通过行业云的方式做业务、科技的输出,带动整个行业共同发展近期成为金融机构科技创新的一个重要方向。其对数据中心的安全性及合规性有着较高要求，从而为金融客户单独建立满足需求的数据中心。该数据处理中心承载着金融系统的存储和计算负荷，必须具备海量数据存储能力及大规模数据处理能力，能够支撑金融客户需求。',
            active: false,
          },
          {
            name: '医疗行业',
            img: '/img/sy_hyt/ylm.jpg',
            text1: '医疗行业',
            text2: 'medical treatment',
            text: 'MEDICAL TREATMENT 医疗行业解决方案',
            content:
              '随着云以指数级别接管技术，医疗行业的云计算潜力巨大。由于云提供按需计算，它已迅速成为首选工具，尤其是当医疗保健机构和医院需要随时部署，访问和处理网络信息时。随着医疗保健法规推动医疗保健行业寻求更好的存储，协作和数据共享技术，迫切需要防止任何数据丢失。由于电子病历（EMR）容易导致数据丢失，因此云已被证明是一种可靠，安全的数据存储介质，可促进更好的协作，同时提供对安全存储的访问并提供对存储数据的远程访问。',
            active: false,
          },
          {
            name: '智能安防',
            img: '/img/sy_hyt/znm.jpg',
            text1: '智能安防',
            text2: 'Intelligent security',
            text: 'INTELLIGENT SECURITY 智能安防解决方案',
            content:
              '在智能监控的领域中，无论是在那个方面监控，监控的存储都是采用的是磁盘阵列，硬盘，SD卡等进行存储。客户端与设备端进行通讯大多都是通过服务器或客户端获取设备数据播放。在提供一种基于云端实现智能安防的方法，解决现有技术中读写不稳定、无法分析处理数据、使用范围小的问题。',
            active: false,
          },
          {
            name: '公共服务',
            img: '/img/sy_hyt/ggm.jpg',
            text1: '公共服务',
            text2: 'Public services',
            text: 'PUBLIC SERVICES 公共服务解决方案',
            content:
              '一种社区公共服务云系统平台，通过建立包容的“公共系统云”，创建有远程和近程良好通信信道的“公共系统云”、“社区装置”、“服务装置”构成的系统，充分使用手机APP软件的功能，为没有能力独立建设高要求体系的社区提供了无人化付费服务的托管管理,提供了系统服务的公共服务平台和方法，达到了非常方便实用的要求，可以广泛用于社区有付费服务的社区应用。',
            active: false,
          },
          {
            name: '电子商务',
            img: '/img/sy_hyt/dzm.jpg',
            text1: '电子商务',
            text2: 'E-commerce',
            text: 'E-COMMERCE 电子商务行业解决方案',
            content:
              '电子商务企业选择云服务可以根据自身业务需求变化而对资源进行弹性扩展，最大程度上避免资源浪费，降低成本。基于云服务原理的平台，可以解决数据处理问题，从而带来一体化、专业化服务，同时也提高了企业发展的灵活性。',
            active: false,
          },
          {
            name: '互联网行业',
            img: '/img/sy_hyt/hlm.jpg',
            text1: '互联网行业',
            text2: 'Internet',
            text: 'INTERNET 互联网行业解决方案',
            content:
              '云服务作为未来的发展趋势，将深刻影响互联网行业，并驱使大大小小的互联网企业进行创新与转型。',
            active: false,
          },
        ];
      }
      if (this.type === 'KVM') {
        this.cdnBotList = [
          {
            name: '直播互动',
            img: '/img/KVM_slices/zbhd.png',
            obj: {
              bac: '/img/KVM_slices/zbhdbac.png',
              text: '• 提供可靠的服务器产品保障及稳定的内外网带宽资源。众多基础云产品可以帮助用户快速迭代，减少在基础资源层面的人力投入，节约维护成本。\n\n• 使用场景\n大型集团会议直播  社交直播平台\n互联网通信云',
              img: '/img/KVM_slices/zbhdtop.png',
            },
            active: true,
          },
          {
            name: '深度学习',
            img: '/img/KVM_slices/sdxx.png',
            obj: {
              bac: '/img/KVM_slices/sdxxbac.png',
              text: '• 提供满足业务需求和性能指标的丰富的公有云基础服务，同时满足业务的扩展和连续性。流量治理统一化，设备端统一入口，不同的路径相同的服务形式，可以帮助用户快速敏捷部署。\n\n• 使用场景\n人工智能安防 物联网 计算机视觉',
              img: '/img/KVM_slices/sdxxtop.png',
            },
            active: false,
          },
          {
            name: '泛互联网',
            img: '/img/KVM_slices/fhlw.png',
            obj: {
              bac: '/img/KVM_slices/fhlwbac.png',
              text: '• 针对已在线使用web服务迁移，我们提供稳定快速的迁移方案，保证业务的连续性和数据的一致性，为用户降低迁移和部署成本。\n\n• 使用场景\n媒体   资讯类网站   电商   企业网站   企业应用',
              img: '/img/KVM_slices/fhlwtop.png',
            },
            active: false,
          },
        ];
        this.cdnlist = [
          {
            name: '弹性计算',
            img: '/img/KVM_slices/txjs.png',
            text: '您可以在几分钟之内快速增加或删减KVM数量，以满足快速变化的业务需求。通过定义相关策略，您可以确保所使用的KVM实例数量在需求高峰期无缝扩展，保证程序的可用性。',
          },
          {
            name: '多样化配置',
            img: '/img/KVM_slices/dyhpz.png',
            text: 'KVM提供多种类型的实例、操作系统和软件包。各实例中的 CPU、内存、硬盘和带宽可以灵活调整，以满足您应用程序的资源需要。',
          },
          {
            name: '稳定可靠',
            img: '/img/KVM_slices/wdkk.png',
            text: 'KVM提供达99.975%的服务可用性和9个9的数据可靠性。三副本存储策略的云硬盘、成熟的网络虚拟技术和网卡绑定技术、T3级以上的数据中心等联合保证数据和服务的高可用性。',
          },
          {
            name: '管理简单',
            img: '/img/KVM_slices/gljd.png',
            text: '用户拥有KVM的管理员账号，对KVM有完全的控制权，您可以使用控制台、API 或 CLI 等工具登录到您的 KVM实例，进行网络配置更改、重启等重要操作，这样管理KVM就像管理操作您的计算机一样简单方便。',
          },
          {
            name: '安全的网络',
            img: '/img/KVM_slices/aqdwl.png',
            text: 'KVM运行在一个逻辑隔离的私有网络里，通过网络访问控制列表和安全组，切实保证您云上资源的安全性。您还可以完全掌控您的私有网络环境配置，包括自定义网段划分、IP 地址和路由策略等。',
          },
          {
            name: '费用低廉',
            img: '/img/KVM_slices/fydl.png',
            text: 'KVM部署在云端，极大节省了您前期搭建基础网络设施的成本和后期的维护成本。KVM实例支持按量付费和包月包年两种计费方式，您可以根据使用场景灵活选择计费方式。',
          },
        ];
      }
      if (this.type === 'SD-WAN') {
        this.cdnBotList = [
          {
            name: '多分支一键上云',
            img: '/img/SD-WAN_slices/dfz.png',
            obj: {
              bac: '/img/SD-WAN_slices/dfzbac.png',
              text: '• SD-WAN 接入服务支持 ZeroTouch 部署方式，帮助多分支机构快速实现云上互联，提供稳定可靠、极简管控、安全无忧、高性价比的上云服务。',
              img: '/img/SD-WAN_slices/dfztop.png',
            },
            active: true,
          },
          {
            name: '快速构建混合云',
            img: '/img/SD-WAN_slices/ksg.png',
            obj: {
              bac: '/img/SD-WAN_slices/ksgbac.png',
              text: '• 腾讯云 SD-WAN 接入服务支持混合组网，轻松构建企业分支、私有云、公有云不同地域 VPC 、用户 IDC 等多云互联。同时腾讯云的接入点覆盖20+个地域，采用网状互联结构， Tb 级网络，保证大带宽和低时延通信。',
              img: '/img/SD-WAN_slices/ksgtop.png',
            },
            active: false,
          },
        ];
        this.cdnlist = [
          {
            name: '部署更快',
            img: '/img/SD-WAN_slices/bsgk.png',
            text: '支持 Zero Touch 部署，即插即用。通过 4G 网络连接云端控制台，自动获取云端配置并下发至本地分支，最快1分钟即可完成部署上线。',
          },
          {
            name: '稳定可靠',
            img: '/img/SD-WAN_slices/wdkk.png',
            text: '提供 Edge 设备级、链路级、接入点三级容灾，有效避免单点故障，全方位多维度地保障业务稳定性。',
          },
          {
            name: '智简运维',
            img: '/img/SD-WAN_slices/zsyw.png',
            text: '提供可视化网络拓扑管理界面，可实时监控网络、链路状态，智能更新，快速收敛。帮助您轻松实现网络全方位、智能化管理。',
          },
          {
            name: '灵活接入',
            img: '/img/SD-WAN_slices/lhjr.png',
            text: '支持 Internet 、专线、4G 等多种接入方式，不受现有网络环境限制，您可以根据不同的网络情况灵活选择，构建多种异构网络的互联通信。',
          },
          {
            name: '多地域覆盖',
            img: '/img/SD-WAN_slices/ddyfg.png',
            text: '20+地域数据中心互联，30+线 BGP 接入，并持续新建边缘 POP 节点，采用网状互联结构， Tb 级网络，保证大带宽和低时延通信。',
          },
          {
            name: '高性价比',
            img: '/img/SD-WAN_slices/gxjb.png',
            text: '大幅降低网络带宽成本，较于同等带宽的专线产品，每年至少为您节省 30% CAPX 。',
          },
        ];
      }
      if (this.type === 'byjspt') {
        this.cdnBotList = [
          {
            name: 'CDN',
            img: '/img/byjspt_yx_slices/CDN.png',
            obj: {
              bac: '/img/byjspt_yx_slices/CDNbac.png',
              text: '• 边缘节点服务提供海量的边缘算力，帮助用户快速搭建云化CDN节点，对客户部署在全国各地的云化vCDN边缘站点进行统一管理，帮助用户实现应用调度，伸缩以及边缘站点和边缘应用运维。\n\n• 优势\n覆盖全国300+地市，提供边缘多样化算力的边缘节点\n可视化运维，实时监控业务健康状态和边缘节点状态\n支持边缘节点自治，弱网环境下边缘节点的业务扔可以正常运行',
              img: '/img/byjspt_yx_slices/CDNtop.png',
            },
            active: true,
          },
          {
            name: '边缘AI',
            img: '/img/byjspt_yx_slices/AI.png',
            obj: {
              bac: '/img/byjspt_yx_slices/AIbac.png',
              text: '• 满足金融、教育、医疗、安防、零售、工业制造等多种行业的图像识别、视频分析、语音识别、推荐、预测等多种业务场景，实现云、边、端协同，数据边缘节点计算处理，必要数据回传上云进行模型训练，训练完成将模型导入边缘节点，优化边缘节点计算算法，实现对设备精确控制 。\n\n• 优势\n灵活定制边缘智能能力，快速部署AI等算法\n弥补端侧设备算力不足云上，AI能力延伸边缘，开箱即用\n就近访问边缘节点，实时计算处理，降低处理时延',
              img: '/img/byjspt_yx_slices/AItop.png',
            },
            active: false,
          },
          {
            name: '云游戏',
            img: '/img/byjspt_yx_slices/yyx.png',
            obj: {
              bac: '/img/byjspt_yx_slices/yyxbac.png',
              text: '• 面向大型云游戏头部厂商提供“网+边”服务，面向中小云游戏厂商提供“网+边+平台”服务。满足云游戏对超低时延、超大带宽、超多算力的边缘需求，协助云游戏厂商向游戏用户提供无卡顿、高清画质等极致游戏体验。\n\n• 优势\n提供分布于全网的边缘云算力、节点调度、节点运维监控能力\n具备边缘虚拟化（X86+ARM）/容器化/GPU等能力\n云游戏用户就近接入，游戏数据本地处理，实现超低时延无卡顿',
              img: '/img/byjspt_yx_slices/yyxtop.png',
            },
            active: false,
          },
        ];
        this.cdnlist = [
          {
            name: '全域覆盖',
            img: '/img/byjspt_yx_slices/qyfg.png',
            text: '基于丰富的边缘网络、机房及硬件基础设施构建边缘云节点，提供可覆盖全国300+地市的边缘多样化算力，保障终端用户低时延。',
          },
          {
            name: '快速交付',
            img: '/img/byjspt_yx_slices/ksjf.png',
            text: '全域边缘资源分钟级交付、客户节点分钟级接入、镜像快速下发、资源弹性伸缩，先用后付、按需订购、按量付费。',
          },
          {
            name: '资源多样',
            img: '/img/byjspt_yx_slices/zzdy.png',
            text: '提供多样化的边缘算力，包括不同规格边缘虚拟机、裸金属、容器、边缘带宽及边缘存储，融合边缘硬件加速服务。',
          },
          {
            name: '安全可靠',
            img: '/img/byjspt_yx_slices/aqkk.png',
            text: '基于移动云统一运维监控体系，提供边缘7*24小时全方位实时监控；边缘节点安全接入云端，应用数据安全上云。',
          },
          {
            name: '应用托管',
            img: '/img/byjspt_yx_slices/yytg.png',
            text: '支持多种形态边缘容器托管集群，自助完成边缘节点实例的容器化管理，帮助用户在云端统一对边缘应用进行管理、监控和运维。',
          },
          {
            name: '一体化协同',
            img: '/img/byjspt_yx_slices/yjxth.png',
            text: '边缘资源全局调度、云边应用统一编排、云边数据自定义交互、边缘设备集中管理，提供资源、应用、数据等多维度协同能力。',
          },
        ];
      }
      if (this.type === 'yyxjjfa') {
        this.cdnBotList = [
          {
            name: '云游戏方案',
            img: '/img/byjspt_yx_slices/yyx.png',
            obj: {
              bac: '/img/byjspt_yx_slices/yyxfabac.png',
              text: '适用场景\n\n• 全在线玩游戏\n游戏玩家可使用PC电脑、智能手机、PAD平板、智慧大屏、VR/AR等智能终端，免下载，免安装，随时随地接入，畅玩3A 游戏\n\n• 游戏试玩广告\n游戏运营平台可基于云游戏提供新游戏试玩推广，在各类流量渠道提供游戏互动广告，用户免下载，直接畅快玩游戏，提高推广转化率、降低推广成本\n\n• 游戏直播互动\n云游戏将与游戏竞技直播平台深入互动，主播玩游戏，粉丝可无缝接入，互动性大幅增强，产生爆发流量，高爆发用户增长',
              img: '/img/byjspt_yx_slices/yyxtop.png',
            },
            active: true,
          },
        ];
        this.cdnlist = [
          {
            name: '突破终端算力，低配置可玩高配游戏',
            img: '/img/byjspt_yx_slices/tp.png',
            text: '云游戏将3A级游戏运行在云端，普通设备轻松接入即可体验高配置游戏，基于领先业界的企业级云游戏架构，提供高性能云上环境，突破终端算力瓶颈，让高品质游戏无处不在，让更多玩家体验到游戏带来前所未有的乐趣',
          },
          {
            name: '免下载，免安装，随时接入',
            img: '/img/byjspt_yx_slices/mx.png',
            text: '玩家可通过PC、手机、机顶盒、智慧大屏等终端，免下载，免安装，随时随地畅玩各类高品质游戏，按需付费；云游戏平台基于牧云-管-端 整体协同优化，游戏时延最低50ms，带来良好体验',
          },
          {
            name: '游戏可跨平台访问，极致体验',
            img: '/img/byjspt_yx_slices/yyk.png',
            text: '游戏架构支持多终端接入，提供云资源的自动化管理、适配和部署环境，极大简化业务对接和部署难度，为游戏企业提供一站式服务接入。 游戏玩家可通过不同类型的终端，接入同一款游戏，也可以在一台设备上，畅玩不同分发平台的游戏',
          },
          {
            name: '行业创新，良性发展',
            img: '/img/byjspt_yx_slices/hycx.png',
            text: '游戏推广摆脱传统图片、视频转化方式，转变为用户即点即玩，快速识别游戏品质和风格，将加速高品质游戏脱颖而出、低端游戏快速被淘汰，加速游戏向高品质发展。同时，云游戏将对游戏内容、游戏使用时长实现管控，且有效防止外挂和盗版，将促进行业向精品化发展，推动行业进步',
          },
        ];
      }
      //应用场景默认选中第一个
      this.cdnObj = this.cdnBotList[0];
      this.$nextTick(() => {
        document.querySelector('.mapp').scrollTop = 0;
      });
    },
    isPopupShow(val) {
      if (val) {
        this.$refs.headMenu.style =
          'transition-property:background-color;transition-timing-function: linear;transition-duration:0.5s;background-color:rgba(255,255,255,1)';
      } else {
        this.$refs.headMenu.style =
          'transition-property:background-color;transition-timing-function: linear;transition-duration:0.5s;background-color:rgba(255, 255, 255, 0.4)';
        this.$nextTick(() => {
          this.$refs.headMenuContent.scrollTop = 0;
        });
      }
    },
  },
  //方法集合
  methods: {
    //菜单跳转
    jump(type) {
      this.$router.push(`/${type}`);
    },
    //首页内容2标签切换
    homeContentClick(index) {
      this.homeContentList2.forEach((res) => {
        res.active = false;
      });
      this.homeContentList2[index].active = true;
      this.activeName = index;
    },
    //KVM产品类型内容切换
    kvmContentClick(index) {
      this.kvmContentList.forEach((res) => {
        res.active = false;
      });
      this.kvmContentList[index].active = true;
      this.activeKvmName = index;
    },
    //监听内容区域滚动事件动态设置背景色
    scrollbarScroll(val) {
      if (Number(val.target.scrollTop) !== 0) {
        this.$refs.headMenu.style =
          'transition-property:background-color;transition-timing-function: linear;transition-duration:0.5s;background-color:rgba(255,255,255,1);box-shadow: 0px 14px 36px 0px rgba(56, 66, 86, 0.12);';
      } else {
        this.$refs.headMenu.style =
          'transition-property:background-color;transition-timing-function: linear;transition-duration:0.5s;background-color:rgba(255, 255, 255, 0.4)';
      }
    },
    //应用场景切换
    cdnClick(index) {
      this.cdnBotList.forEach((res) => {
        res.active = false;
      });
      this.cdnBotList[index].active = true;
      this.cdnObj = this.cdnBotList[index];
    },
    //广泛应用场景切换
    gfyycjClick(index) {
      this.gfyycjListactive = index;
    },
    //菜单按下背景色
    serviceListClick(index) {
      var service = document.querySelectorAll('.headMenuContent-1')[index];
      service.style.background = '#F5F6F7';
      setTimeout(() => {
        service.style.background = '#fff';
      }, 200);
    },
    //跳转备案
    torecords() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index');
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeUnmount() {}, //生命周期 - 销毁之前
  unmounted() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
body {
  overflow: hidden;
}
//s
.mapp {
  overflow-y: auto;
  height: 100vh;
  .headMenu {
    height: 44px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(4px);
    padding: 0 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .headMenu-left-content-logo {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .labellogo {
    &::after {
      content: '';
      height: 1px;
      background: #f0f3f7;
      width: 100vw;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .logoImgleft {
    // width: 86px;
    height: 32px;
  }
  .headMenu_mapp_right {
    width: 24px;
    height: 24px;
  }

  //首页内容1
  .home-content {
    background: #09193a;
    .home-content-img {
      padding: 20px 24px;
      box-sizing: border-box;
      background: #203662;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      height: calc(100vw * 0.448);
      position: relative;
      &:not(:first-child) {
        margin-top: 18px;
      }
      .img {
        position: absolute;
        top: 0;
        z-index: -1;
        left: 0;
      }
      .text {
        .text1 {
          height: 26px;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 26px;
        }
        .text2 {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          opacity: 0.8;
          margin-top: 6px;
        }
      }
      .tag {
        position: absolute;
        left: 24px;
        bottom: 24px;
        display: flex;
        .tag-1 {
          padding: 0 8px;
          height: 24px;
          border-radius: 4px;
          border-color: rgba(255, 255, 255, 0.4);
          border: 1px solid #dce3ec;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
          opacity: 0.9;
          &:not(:first-of-type) {
            margin-left: 8px;
          }
        }
      }
      .hot {
        height: 16px;
        font-size: 14px;
        font-family: GillSans;
        color: #ff5e1e;
        line-height: 16px;
        margin-left: 5px;
        font-weight: 400;
      }
    }
  }
  //首页内容2
  .home-content2 {
    position: relative;
    //关于我们
    .slicehover {
      width: calc(100% - 48px);
      // margin-right: 27px;
      position: absolute;
      top: -20px;
      height: 160px;
      box-sizing: border-box;
      .slicehover1 {
        height: 98px;
        &:first-child {
          margin-bottom: 12px;
        }
        padding: 14px 20px;
        box-sizing: border-box;
        position: relative;
        background: #ffffff;
        box-shadow: 0px 14px 36px 0px rgba(56, 66, 86, 0.08);
        border-radius: 12px;
        .left {
          width: 48px;
          margin-right: 20px;
        }
        .left1 {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -10;
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
        }
        display: flex;
        align-items: center;
        .name {
          height: 22px;
          font-size: 14px;
          font-weight: 400;
          color: #0281ff;
          line-height: 22px;
        }
        .text {
          font-size: 14px;
          font-weight: 500;
          color: #1d2129;
          line-height: 22px;
        }
      }
    }
    .label-content {
      background: #ffffff;
      border-radius: 8px;
      padding: 16px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .content {
      margin-top: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #4e5969;
      line-height: 24px;
    }
    .img2 {
      border-radius: 4px;
      margin-top: 16px;
    }
    .col {
      font-weight: 500;
      color: #0281ff;
    }
  }
  //加入我们
  .gywm-jrwm {
    position: relative;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .bottom {
      position: absolute;
      bottom: 32px;
      left: 0;
      width: 100%;
    }
    text-align: center;
    .text1 {
      font-size: 16px;
      font-weight: 500;
      color: #1d2129;
      line-height: 24px;
      &:nth-child(2) {
        padding: 0 42px;
      }
    }
    .text2 {
      font-size: 14px;
      font-weight: 400;
      color: #86909c;
      line-height: 22px;
      padding: 0 24px;
    }
    .logoImg {
      // width: 80px;
      height: 32px;
      position: relative;
      top: 17%;
      transform: skewY(10deg);
    }
  }
  //首页内容3
  .home-content3 {
    .home-content3-link {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .linkimg {
        width: 47%;
        background: #ffffff;
        box-sizing: border-box;
        padding: 10px;
        box-shadow: 0px 4px 28px 0px rgb(71 78 90 / 4%);
        border-radius: 8px;
        margin-bottom: 36px;
        &:nth-last-child(1),
        &:nth-last-child(2) {
          margin-bottom: 0px;
        }
      }
    }
  }
  //尾部
  .foot {
    .foot-left-content {
      margin: 5px 0;
      &:first-child,
      &:nth-last-of-type(3) {
        font-size: 14px;
        margin: 10px 0 8px 0;
      }
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-family: GillSans;
      color: #86909c;
      .foot-left-content-1,
      .foot-left-content-2 {
        display: flex;
        width: 50%;
        height: 22px;
        font-weight: 400;
        color: #4e5969;
        line-height: 22px;
        align-items: center;
      }
    }
    .bor {
      padding-bottom: 27px;
      border-bottom: 1px solid #f0f3f7;
    }
    .end {
      margin-top: 27px;
      .end-1,
      .end-2,
      .end-3 {
        height: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #86909c;
        line-height: 20px;
        text-align: center;
      }
    }
  }

  //导航菜单
  .headMenuContent {
    z-index: 10;
    background-color: #ffffff;
    max-height: 80%;
    overflow-y: auto;
    .mapp-publicpadding {
      padding: 0 0 !important;
    }
    .label {
      :not(:last-child) {
        &::after {
          content: '';
          height: 1px;
          background: #f0f3f7;
          width: calc(100vw - 54px);
          position: absolute;
          left: 27px;
          right: 27px;
          bottom: 0;
        }
      }
    }
    .headMenuContent-1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      font-size: 16px;
      font-weight: 500;
      color: #1d2129;
      padding: 0 27px;
      height: 48px;
      line-height: 48px;
    }
    .headMenuContent-2 {
      font-size: 14px;
      font-weight: 500;
      color: #1d2129;
      padding: 0 27px;
      height: 48px;
      line-height: 48px;
    }
    .djz {
      display: inline-block;
      padding: 2px 5px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #dce3ec;
      margin-left: 6px;
    }
    .content-1 {
      padding-right: 27px;
      font-size: 14px;
      font-weight: 400;
      color: #4e5969;
      line-height: 22px;
      display: flex;
      .content-1-1 {
        display: flex;
        align-items: center;
        margin-left: 27px;
        padding: 5px 0;
      }
      flex-wrap: wrap;
    }
  }
  //头部公共部分
  .bannerSlice {
    .banner {
      height: 450px;
      position: relative;
      .img {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
      }
      .clo {
        ::v-deep .el-button {
          background-color: #0281ff;
        }
      }
    }
  }
  .banner1 {
    padding: 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    .name {
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #1d2129;
      line-height: 28px;
    }
    .text {
      font-size: 14px;
      font-weight: 400;
      color: #4e5969;
      line-height: 22px;
      margin: 10px 0;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 4;
      // -webkit-box-orient: vertical;
    }
  }
  .banner2 {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    text-align: center;
    .name {
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #1d2129;
      line-height: 28px;
    }
    .text {
      font-size: 14px;
      font-weight: 400;
      color: #4e5969;
      line-height: 22px;
      margin: 10px 0;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 4;
      // -webkit-box-orient: vertical;
    }
  }
  //产品特性
  .CDN1 {
    .slicehover2 {
      .slicehover12 {
        border-radius: 12px;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        &:last-child {
          margin-bottom: 27px;
        }
        padding: 0 17px;
        box-sizing: border-box;
        .name {
          height: 24px;
          font-size: 16px;
          font-weight: 500;
          color: #1d2129;
          line-height: 24px;
        }
        .text {
          margin-top: 5px;
          width: 60%;
          font-size: 14px;
          font-weight: 400;
          color: #86909c;
          line-height: 22px;
        }
      }
    }
    .CDN1-img-1 {
      display: flex;
      align-items: center;
    }
    .CDN1-img {
      margin: 10px 0 34px 0;
    }
    .CDN1-img {
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .text1 {
      height: 24px;
      font-size: 16px;
      font-weight: 500;
      color: #1d2129;
      line-height: 24px;
    }
    .text2 {
      font-size: 14px;
      font-weight: 400;
      color: #86909c;
      line-height: 24px;
    }
    .canme {
      margin-top: 34px;
      height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: #86909c;
      line-height: 22px;
      text-align: center;
    }
  }
  //应用场景
  .CDN2 {
    .CDN2-left {
      position: relative;
      &::after {
        content: '';
        width: 100vw;
        position: absolute;
        bottom: 0;
        left: -24px;
        right: 0;
        height: 1px;
        background: #e4eaf0;
      }
    }
    .bot {
      position: relative;
      height: 50px;
      display: flex;
      white-space: nowrap;
      overflow-x: auto;
      .botimg {
        position: relative;
        &:not(:first-child) {
          margin-left: 15px;
        }
        display: flex;
        align-items: center;
        .bimg {
          width: 24px;
          margin-right: 5px;
        }
      }
      .isActiveHover::after {
        content: '';

        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        background: #0281ff;
        border-radius: 2px;
      }
    }
    .text {
      font-size: 14px;
      font-weight: 400;
      color: #4e5969;
      line-height: 24px;
    }
    .tex {
      white-space: pre-line;
    }
  }
  //节点分布
  .CDN3 {
    .CDN3-left {
      font-size: 14px;
      font-weight: 400;
      color: #4e5969;
      line-height: 24px;
      margin: 16px 0;
    }
    .CDN3-bot-c {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .CDN3-bot-left {
        padding-bottom: 16px;
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
          padding-bottom: 0px;
        }
        .img {
          width: 9px;
          margin-right: 10px;
        }
        display: flex;
        align-items: center;
        .CDN3-bot-left-name {
          font-size: 12px;
          font-weight: 400;
          color: #86909c;
          line-height: 20px;
        }
        .CDN3-bot-left-num {
          font-size: 20px;
          font-family: GillSans;
          color: #1d2129;
          line-height: 28px;
        }
      }
    }
    .gn {
      justify-content: start;
    }
  }
  //首页轮播2
  .my-swipe-1 {
    background: #0c0d0f;
    position: relative;
    height: 510px;
    ::v-deep .van-swipe__indicators {
      display: flex;
      align-items: center;
      bottom: 50px;
      .van-swipe__indicator {
        width: 8px;
        height: 8px;
        background: #ffffff;
        border-radius: 50%;
        opacity: 0.5;
      }
      .van-swipe__indicator--active {
        width: 12px;
        height: 12px;
        background: #ffffff;
        opacity: 1;
      }
    }
    .cont {
      position: absolute;
      bottom: 58px;
      padding: 24px;
      text-align: center;
      .text {
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        line-height: 28px;
      }
      .content {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        opacity: 0.8;
      }
    }
  }
  // 产品类型
  .cplx {
    .label-content {
      border-radius: 8px;
      .content {
        position: relative;
        background: #ffffff;
        padding: 16px;
        border-radius: 8px;
        &:nth-of-type(1) {
          margin: 20px 0 16px 0;
        }
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        .img {
          position: absolute;
          top: 0;
          right: 0;
          transform: rotate(90deg);
        }
        .left {
          border-bottom: 1px solid #edf2f7;
          padding-bottom: 8px;
        }
        .left-1 {
          height: 24px;
          font-size: 16px;
          font-weight: 500;
          color: #1d2129;
          line-height: 24px;
        }
        .left-2 {
          font-size: 14px;
          font-weight: 400;
          color: #86909c;
          line-height: 22px;
          margin: 4px 0 16px 0;
        }
        .left-c-c {
          display: flex;
          margin-bottom: 8px;
        }
        .c-1 {
          height: 22px;
          font-size: 14px;
          font-weight: 400;
          color: #4e5969;
          line-height: 22px;
          width: 32%;
        }
        .c-2 {
          flex: 1;
          height: 22px;
          font-size: 14px;
          font-weight: 500;
          color: #1d2129;
          line-height: 22px;
        }
        .right {
          padding-top: 16px;
          .right-1 {
            height: 22px;
            font-size: 14px;
            font-weight: 500;
            color: #1d2129;
            line-height: 22px;
          }
          .right-c-c {
            margin-top: 8px;
            padding: 8px 0;
          }
          .right-c-c-c {
            padding: 8px;
            background: rgba(2, 129, 255, 0.08);
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            color: #1d2129;
          }
        }
      }
    }
  }
  //安华东莞数据中心
  .bannerAhSlice {
    .img {
      height: 278px;
      position: relative;
      .ssimg {
        box-sizing: border-box;
        width: 100%;
        position: absolute;
        bottom: -40px;
        height: 80px;
        background: linear-gradient(
          180deg,
          rgba(8, 118, 227, 0) 0%,
          #0281ff 100%
        );
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .simg {
          box-sizing: border-box;
          width: 23.5%;
          height: 70%;
          border-radius: 4px;
          // border: 2px solid #ffffff;
        }
      }
    }
    .mcc {
      position: relative;
      background: #0281ff;
      .mcc1 {
        display: flex;
        align-items: center;
        .mcc1-1 {
          height: 32px;
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          line-height: 32px;
        }
      }
      .mcc2 {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        margin: 12px 0 22px 0;
      }
      .mcc3 {
        display: flex;
        align-items: center;
        .name {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        }
        .num {
          height: 28px;
          font-size: 20px;
          font-family: GillSans;
          color: #ffffff;
          line-height: 28px;
        }
      }
      .bacimg {
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0.9;
      }
    }
  }
  //轮播图
  .my-swipe {
    ::v-deep .van-swipe__indicators {
      bottom: 32px;
    }
    .banner {
      height: calc(1.84 * 100vw);
      position: relative;
      .banner1 {
        text-align: center;
        position: absolute;
        top: 90px;
        .name {
          height: 36px;
          font-size: 28px;
          font-weight: 500;
          color: #ffffff;
          line-height: 36px;
        }
        .text {
          width: 100% !important;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          opacity: 0.8;
        }
        .tag {
          display: flex;
          justify-content: center;
          margin-top: 16px;
          .tag-div {
            padding: 0 16px;
            height: 30px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 4px;
            line-height: 30px;
            box-sizing: border-box;
            &:not(:last-child) {
              margin-right: 8px;
            }

            span {
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
        .bac {
          margin-top: 24px;
          ::v-deep .el-button--primary {
            width: 96px;
            background-color: rgba(220, 38, 38, 0);
            height: 36px;
            border-radius: 2px;
            border: 1px solid #ffffff;
          }
        }
      }
    }
  }
  //边缘云计算
  .gfyycj {
    .CDN2-right {
      height: 170px;
      padding: 24px 0 32px 0;
      position: relative;
      &::after {
        content: '';
        width: 100vw;
        position: absolute;
        top: 0;
        left: -24px;
        right: 0;
        height: 1px;
        background: #e4eaf0;
      }
    }
    .bot {
      position: relative;
      height: 50px;
      display: flex;
      white-space: nowrap;
      overflow-x: auto;
      .botimg {
        position: relative;
        margin-left: 15px;
        display: flex;
        align-items: center;
        .bimg {
          width: 24px;
          margin-right: 5px;
        }
      }
      .isActiveHover::after {
        content: '';

        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        background: #0281ff;
        border-radius: 2px;
      }
    }
    .text {
      font-size: 14px;
      font-weight: 400;
      color: #4e5969;
      line-height: 24px;
    }
    .title {
      height: 24px;
      font-size: 14px;
      font-weight: 500;
      color: #1d2129;
      line-height: 24px;
      margin: 10px 0;
    }
    .gfyycj-foot {
      display: flex;
      flex-wrap: wrap;
      .gfyycj-foot-1 {
        padding: 4px;
        margin-right: 8px;
        border-radius: 4px;
        border: 1px solid #dce3ec;
        font-size: 12px;
        font-weight: 400;
        color: #4e5969;
        line-height: 20px;
      }
    }
  }
}
// end
::v-deep .van-swipe__indicator {
  width: 20px;
  height: 2px;
  background: #ffffff;
  border-radius: 2px;
}
::v-deep .van-overlay {
  top: 44px;
  overflow: hidden;
}
::v-deep .el-button--primary {
  height: 36px;
}
::v-deep .el-radio-button__inner {
  padding: 8px 25px !important;
}
.van-overlay {
  height: 100vh;
}
</style>
