import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './css/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import App from './wapp.vue';
import router from './router';
import store from './store';
createApp(App).use(store).use(router).use(ElementPlus).use(Vant).mount('#app');
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  let content = '牧云时代，CDN，边缘容器云，边缘裸金属云，边缘云计算';
  let xqcontent =
    '依托牧云全球边缘云架构，为企业提供安全、稳定、便捷、高效的IDC、CDN加速、裸金属云、SD-WAN、KVM、边缘计算平台等丰富的产品及服务，在企业的数字化转型过程中伴您通行……';
  let type = to.path.split('/')[1];
  if (type === 'CDN') {
    content = '牧云时代，CDN，内容分发网络，网络加速，边缘节点';
  }
  if (type === 'SD-WAN') {
    content = '牧云时代，SD-WAN，多分支互联，混合云';
  }
  if (type === 'byjsy') {
    content = '牧云时代，裸金属，物理服务器，VPC';
  }
  if (type === 'byrqy') {
    content = '牧云时代，容器技术，K8s，Docker';
  }
  if (type === 'byjspt') {
    content = '牧云时代，边缘计算平台，边缘AI，云游戏';
  }
  if (type === 'ahdgsjzx') {
    content = '牧云时代，数据中心，IDC，机柜';
  }
  if (type === 'gywm') {
    content = '牧云时代，牧云科技，边缘计算，云游戏';
  }
  if (type === 'KVM') {
    content = '牧云时代，KVM，云主机，虚拟化';
  }
  if (type === 'yyxjjfa') {
    content = '牧云时代，云游戏，云渲染，游戏平台';
  }
  let meta = document.querySelector('meta[name="keywords"]');
  let metadescription = document.querySelector('meta[name="description"]');
  document
    .querySelector('meta[name="keywords"]')
    .setAttribute('content', content);
  meta.content = content;
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', xqcontent);
  metadescription.content = xqcontent;
  // /* 路由发生变化修改页面title */
  // document.title = content
  next();
});
